import { useContext, useEffect } from "react";
import { GlobalContext } from "src/context/GlobalState";
import { messaging, onMessage } from "../../firebase/config";

const Notifications = () => {
  const {
    dispatch,
    state: { alertState }
  } = useContext(GlobalContext);

  useEffect(() => {
    // Listen for messages when the app is in the foreground
    const notificationSound = new Audio("/assets/audio/mixkit-confirmation-tone-2867.wav");
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Foreground Message received:", payload);
      if (!payload.notification) return;
      // You can show a custom notification or alert here
      notificationSound.play().catch((error) => {
        console.error("Error playing notification sound:", error);
      });
      const { title, body } = payload.notification;
      //   new Notification(title, { body });
      dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "Message", heading: title, body: body, type: "success" } });
      dispatch({ type: "showMessage", payload: true });
    });

    return () => {
      // Clean up the listener when the component unmounts
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);
  return null;
};

export default Notifications;
