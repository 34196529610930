export const notificationsInitialState = {
  redirectionTo: 0,
  notificationRecord: {
    title: "",
    body: "",
    richTextTitle: "",
    richTextBody: "",
    productVariantId: null,
    categoryId: null,
    subCategoryId: null,
    pamojaConfigId: null,
    supermojaId: null,
    brandId: null
  }
};

export const notificationsReducer = (state, action) => {
  switch (action.type) {
    case "notificationRecord":
      return { ...state, notificationRecord: action.payload };
    case "redirectionTo":
      return { ...state, redirectionTo: action.payload };
    case "notificationClear":
      return {
        ...state,
        redirectionTo: 0,
        notificationRecord: { title: "", body: "", richTextTitle: "", richTextBody: "", productVariantId: null, categoryId: null, subCategoryId: null, pamojaConfigId: null, supermojaId: null, brandId: null }
      };

    default:
      return state;
  }
};
