import { orderingPlatform } from "src/constants/order_constants";

//intital state
export const orderInitialState = {
  ordersList: [],
  orderDetails: false,
  orderFilter: false,
  jipangeFilter: false,
  orderLoading: false,
  orderConfirmed: false,
  jipangeDownloadLoading: false,
  searchInput: null,
  customerId: [],
  jipangeCustomerIds: [],
  leaderId: [],
  paymentStatuses: [1, 2, 3, 4],
  platform: [orderingPlatform.web, orderingPlatform.app, orderingPlatform.sellerPortal],
  orderStatuses: [1, 2, 3, 4, 5, 6],
  fromDate: null,
  toDate: null,
  fromDateLocal: null,
  toDateLocal: null,
  orderData: {
    customerOrderId: null,
    orderDate: null,
    items: [],
    promoCode: null,
    leaderId: null,
    leaderFirstName: null,
    leaderLastName: null,
    orderStatusId: 1,
    customer: null,
    deliveryDate: null,
    paymentDetails: [],
    deliveryMethod: null,
    totalAmount: null,
    paymentStatus: null
  },
  deliveryMethods: [1, 2, 3, 4, 5, 6],
  purchaseTypes: [1, 3, 4, 5],
  showMarkDeliveredDialog: false,
  showLogsDeliveredDialog: false,
  orderStatusUpdateRecord: {
    orderId: null,
    statusId: null,
    reason: null,
    note: null,
    anotherOrderId: null,
    anotherReason: null
  },
  isPromoApplied: false
};

export const orderReducer = (state, action) => {
  switch (action.type) {
    case "ordersList":
      return { ...state, ordersList: action.payload };
    case "orderDetails":
      return { ...state, orderDetails: action.payload };
    case "orderFilter":
      return { ...state, orderFilter: action.payload };
    case "jipangeFilter":
      return { ...state, jipangeFilter: action.payload };
    case "orderLoading":
      return { ...state, orderLoading: action.payload };
    case "orderConfirmed":
      return { ...state, orderConfirmed: action.payload };
    case "jipangeDownloadLoading":
      return { ...state, jipangeDownloadLoading: action.payload };
    case "searchInput":
      return { ...state, searchInput: action.payload };
    case "customerId":
      return { ...state, customerId: action.payload };
    case "jipangeCustomerIds":
      return { ...state, jipangeCustomerIds: action.payload };
    case "leaderId":
      return { ...state, leaderId: action.payload };
    case "paymentStatuses":
      return { ...state, paymentStatuses: action.payload };
    case "orderStatuses":
      return { ...state, orderStatuses: action.payload };
    case "platform":
      return { ...state, platform: action.payload };
    case "fromDate":
      return { ...state, fromDate: action.payload };
    case "toDate":
      return { ...state, toDate: action.payload };
    case "fromDateLocal":
      return { ...state, fromDateLocal: action.payload };
    case "toDateLocal":
      return { ...state, toDateLocal: action.payload };
    case "orderData":
      return { ...state, orderData: action.payload };
    case "deliveryMethods":
      return { ...state, deliveryMethods: action.payload };
    case "purchaseTypes":
      return { ...state, purchaseTypes: action.payload };
    case "showMarkDeliveredDialog":
      return { ...state, showMarkDeliveredDialog: action.payload };
    case "showLogsDeliveredDialog":
      return { ...state, showLogsDeliveredDialog: action.payload };
    case "isPromoApplied":
      return { ...state, isPromoApplied: action.payload };
    case "orderStatusUpdateRecord":
      return { ...state, orderStatusUpdateRecord: action.payload };
    case "clear":
      return {
        ...state,
        orderDetails: false,
        orderFilter: false,
        jipangeFilter: false,
        searchInput: null,
        customerId: [],
        jipangeCustomerIds: [],
        leaderId: [],
        paymentStatuses: [1, 2, 3, 4],
        orderStatuses: [1, 2, 3, 4, 5, 6],
        platform: [orderingPlatform.web, orderingPlatform.app, orderingPlatform.sellerPortal],
        fromDate: null,
        toDate: null,
        fromDateLocal: null,
        toDateLocal: null,
        orderData: {
          customerOrderId: null,
          orderDate: null,
          items: [],
          promoCode: null,
          leaderFirstName: null,
          leaderLastName: null,
          orderStatusId: 1,
          customer: null,
          deliveryDate: null,
          paymentDetails: [],
          deliveryMethod: null,
          totalAmount: null,
          paymentStatus: null
        },
        deliveryMethods: [1, 2, 3, 4, 5, 6],
        purchaseTypes: [1, 3, 4, 5],
        jipangeDownloadLoading: false,
        showMarkDeliveredDialog: false,
        showLogsDeliveredDialog: false,
        orderStatusUpdateRecord: {
          orderId: null,
          statusId: null,
          reason: null,
          note: null,
          anotherOrderId: null
        },
        isPromoApplied: false
      };
    default:
      return state;
  }
};
