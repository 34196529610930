import { useContext } from "react";
import { CircularProgress, Dialog, DialogContent } from "@mui/material"; // theme css file
import { GlobalContext } from "src/context/GlobalState";
// eslint-disable-next-line

const SystemLoader = () => {
  const {
    state: { globalState }
  } = useContext(GlobalContext);
  return (
    <Dialog open={globalState.loading} onClose={() => null} disableEscapeKeyDown slotProps={{ backdrop: { style: { pointerEvents: "none" } } }}>
      <DialogContent>
        <div style={{ textAlign: "center" }}>
          <CircularProgress style={{ color: "#EF7B22" }} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SystemLoader;
