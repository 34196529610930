import { lazy, useEffect } from "react";
import { Navigate, useNavigate, useRoutes } from "react-router-dom";

// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";

//
import { verifyTokenExpiry } from "./utils/verifyToken";
import { isUserSeller } from "./constants/user_constants";

import { DeliveryRequestProvider } from "./pages/deliver_request/context/deliveryRequestContext";

import Page404 from "./pages/404/Page404";
import LoginPage from "./pages/login/LoginPage";

const OrderDetails = lazy(() => import("./pages/order/OrderDetails"));
const OrdersPage = lazy(() => import("./pages/order/OrdersPage"));
const PamojaPage = lazy(() => import("./pages/pamoja/PamojaPage"));
const PriceDetails = lazy(() => import("./pages/price_matrix/priceDetails"));
const PriceMatrixPage = lazy(() => import("./pages/price_matrix/priceMatrix"));
const ProductsDetails = lazy(() => import("./pages/products/ProductDetails"));
const ProductsPage = lazy(() => import("./pages/products/ProductsPage"));
const PromoDetails = lazy(() => import("./pages/promo/PromoDetails"));
const PromoPage = lazy(() => import("./pages/promo/PromoPage"));
const AuditPage = lazy(() => import("./pages/audit/AuditPage"));
const BrandDetails = lazy(() => import("./pages/brand/BrandDetails"));
const BrandPage = lazy(() => import("./pages/brand/BrandPage"));
const CategoryPage = lazy(() => import("./pages/category/CategoryPage"));
const CRMDetails = lazy(() => import("./pages/crm/CRMDetails"));
const DeliverRequestPage = lazy(() => import("./pages/deliver_request/delivery_request"));
const CRMPage = lazy(() => import("./pages/crm/CRMPage"));
const JipangeConfigurationPage = lazy(() => import("./pages/jipange/JipangeConfigurationPage"));
const ResetPasswordPage = lazy(() => import("./pages/reset_password/ResetPasswordPage"));
const PromoNotificationPage = lazy(() => import("./pages/notification/PromoNotificationPage"));

// ----------------------------------------------------------------------

export default function Router() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));

  // Check token status
  useEffect(() => {
    if (!token) {
      navigate("/login", { replace: true });
    } else {
      verifyTokenExpiry(token, navigate);
    }
  }, [token, navigate]);

  const routes = useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/products" />, index: true },
        { path: "products", element: <ProductsPage /> },
        { path: "products/details/:productVariantId", element: <ProductsDetails /> },
        { path: "brands", element: <BrandPage /> },
        { path: "brands/details/:brandId", element: <BrandDetails /> },
        { path: "orders", element: <OrdersPage /> },
        { path: "", element: <OrdersPage /> },
        {
          path: "orders/delivery-request",
          element: (
            <DeliveryRequestProvider>
              <DeliverRequestPage />
            </DeliveryRequestProvider>
          )
        },
        { path: "orders/details/:orderId", element: <OrderDetails /> },
        { path: "crm", element: <CRMPage /> },
        { path: "crm/details/:customerId", element: <CRMDetails /> },
        { path: "priceMatrix", element: <PriceMatrixPage /> },
        { path: "priceMatrix/details/:productVariantId", element: <PriceDetails /> },
        ...(isSeller
          ? []
          : [
              { path: "categories", element: <CategoryPage /> },
              { path: "promos", element: <PromoPage /> },
              { path: "promos/details/:promoId", element: <PromoDetails /> }
            ])
      ]
    },
    ...(isSeller
      ? []
      : [
          {
            path: "/dashboard/okoa",
            element: <DashboardLayout />,
            children: [
              { element: <Navigate to="/dashboard/products" />, index: true },
              { path: "pamoja", element: <PamojaPage /> },
              { path: "jipange", element: <JipangeConfigurationPage /> }
            ]
          },
          {
            path: "/dashboard/audit",
            element: <DashboardLayout />,
            children: [
              { element: <Navigate to="/dashboard/settings" />, index: true },
              { path: "logs", element: <AuditPage /> },
              { path: "notifications", element: <PromoNotificationPage /> }
            ]
          }
        ]),

    {
      path: "login",
      element: <LoginPage />
    },
    {
      path: "reset-password",
      element: <ResetPasswordPage />
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/products" />, index: true },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> }
      ]
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />
    }
  ]);

  return routes;
}
