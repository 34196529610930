// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import { Suspense, useEffect, useState } from "react";
import { StyledChart } from "./components/chart";
import Notifications from "./components/notification/Notification";
import ScrollToTop from "./components/scroll-to-top";
import { requestPermissionNotification } from "./utils/firebaseUtil";
import SystemLoader from "./components/dialogs/Loader";

// ----------------------------------------------------------------------

export default function App() {
  //Request notification permission
  const [isTokenFound, setTokenFound] = useState(false);
  // eslint-disable-next-line
  const [notificationPermission, setNotificationPermission] = useState(null);

  useEffect(() => {
    if (!isTokenFound) requestPermissionNotification(setTokenFound, setNotificationPermission);
  }, [isTokenFound]);

  return (
    <Suspense fallback={<SystemLoader />}>
      <ThemeProvider>
        <ScrollToTop />
        <StyledChart />
        {isTokenFound && <Notifications />}
        <Router />
      </ThemeProvider>
    </Suspense>
  );
}
