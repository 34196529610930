import { createContext, useMemo, useReducer } from "react";
import combineReducers from "react-combine-reducers";
import { alertInitialState, alertReducer } from "src/reducers/alertReducer";
import { brandInitialState, brandReducer } from "src/reducers/brandReducer";
import { categoryInitialState, categoryReducer } from "src/reducers/categoryReducer";
import { crmInitialState, crmReducer } from "src/reducers/crmReducer";
import { globalInitialState, globalReducer } from "src/reducers/globalReducer";
import { notificationIntitialState, notificationReducer } from "src/reducers/notificationReducer";
import { notificationsReducer, notificationsInitialState } from "src/reducers/notificationsReducer";
import { orderInitialState, orderReducer } from "src/reducers/orderReducer";
import { pamojaInitialState, pamojaReducer } from "src/reducers/pamojaReducer";
import { pmIntitialState, pmReducer } from "src/reducers/pmReducer";
import { productInitialState, productReducer } from "src/reducers/productReducer";
import { promoInitialState, promoReducer } from "src/reducers/promoReducer";
import { superPamojaInitialState, superpamojaReducer } from "src/reducers/supermojaReducer";

export const GlobalContext = createContext();

const [rootReducer, initialState] = combineReducers({
  globalState: [globalReducer, globalInitialState],
  productState: [productReducer, productInitialState],
  brandState: [brandReducer, brandInitialState],
  orderState: [orderReducer, orderInitialState],
  pmState: [pmReducer, pmIntitialState],
  crmState: [crmReducer, crmInitialState],
  pamojaState: [pamojaReducer, pamojaInitialState],
  superpamojaState: [superpamojaReducer, superPamojaInitialState],
  promoState: [promoReducer, promoInitialState],
  categoryState: [categoryReducer, categoryInitialState],
  alertState: [alertReducer, alertInitialState],
  notificationState: [notificationReducer, notificationIntitialState],
  notificationsState: [notificationsReducer, notificationsInitialState]
});

export const Store = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState, (initState) => initState);

  const storeValue = useMemo(() => ({ state, dispatch }), [state]);

  return <GlobalContext.Provider value={storeValue}>{children}</GlobalContext.Provider>;
};
